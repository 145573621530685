import React, { useRef, useState, useEffect } from 'react';
import '../styles/ReviewSlider.css';
import Draggable from './Draggable';
import ReviewImage1 from "../img/review-7.jpg"
import ReviewImage2 from "../img/review-2.jpg"
import ReviewImage3 from "../img/review-3.jpg"
import ReviewImage4 from "../img/review-4.jpg"
import ReviewImage5 from "../img/review-5.jpg"
import ReviewImage6 from "../img/review-6.jpg"
import ReviewImage7 from "../img/review-1.jpg"
import ReviewImage8 from "../img/review-8.jpg"
import ReviewImage9 from "../img/review-9.jpg"
import ReviewImage10 from "../img/review-10.jpg"
import ReviewImage11 from "../img/review-11.jpg"
import ReviewImage12 from "../img/review-11-2.jpg"
import ReviewImage13 from "../img/review-12.jpg"
import ReviewImage14 from "../img/review-13.jpg"
import ReviewImage15 from "../img/review-14.jpg"
import ReviewImage16 from "../img/review-14-2.jpg"
import ReviewImage17 from "../img/review-15.jpg"
import ReviewImage18 from "../img/review-15-2.jpg"
import ReviewImage19 from "../img/review-16.jpg"
import ReviewImage20 from "../img/review-16-2.jpg"
import ReviewImage21 from "../img/review-17.jpg"
import ReviewImage22 from "../img/review-18.jpg"
import ReviewImage23 from "../img/review-19.jpg"
import ReviewImage24 from "../img/review-20.jpg"
import ReviewImage25 from "../img/review-21.jpg"
import ReviewImage26 from "../img/review-22.jpg"
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Reviews = () => {
  const reviews = [
    {
      image: ReviewImage1,
      altText: "Tyson review 1"
    },
    {
      image: ReviewImage2,
      altText: "Tyson review 2"
    },
    {
      image: ReviewImage3,
      altText: "Tyson review 3"
    },
    {
      image: ReviewImage4,
      altText: "Tyson review 4"
    },
    {
      image: ReviewImage5,
      altText: "Tyson review 5"
    },
    {
      image: ReviewImage6,
      altText: "Tyson review 6"
    },
    {
      image: ReviewImage7,
      altText: "Tyson review 7"
    },
    {
      image: ReviewImage8,
      altText: "Tyson review 8"
    },
    {
      image: ReviewImage9,
      altText: "Tyson review 9"
    },
    {
      image: ReviewImage10,
      altText: "Tyson review 10"
    },
    {
      image: ReviewImage11,
      altText: "Tyson review 11"
    },
    {
      image: ReviewImage12,
      altText: "Tyson review 12"
    },
    {
      image: ReviewImage13,
      altText: "Tyson review 13"
    },
    {
      image: ReviewImage14,
      altText: "Tyson review 14"
    },
    {
      image: ReviewImage15,
      altText: "Tyson review 15"
    },
    {
      image: ReviewImage16,
      altText: "Tyson review 16"
    },
    {
      image: ReviewImage17,
      altText: "Tyson review 17"
    },
    {
      image: ReviewImage18,
      altText: "Tyson review 18"
    },
    {
      image: ReviewImage19,
      altText: "Tyson review 19"
    },
    {
      image: ReviewImage20,
      altText: "Tyson review 20"
    },
    {
      image: ReviewImage21,
      altText: "Tyson review 21"
    },
    {
      image: ReviewImage22,
      altText: "Tyson review 22"
    },
    {
      image: ReviewImage23,
      altText: "Tyson review 23"
    },
    {
      image: ReviewImage24,
      altText: "Tyson review 24"
    },
    {
      image: ReviewImage25,
      altText: "Tyson review 25"
    },
    {
      image: ReviewImage26,
      altText: "Tyson review 26"
    },
  ];

  const extendedReviews = [
    ...reviews,
    ...reviews, // Duplicate to create infinite effect
    ...reviews,
  ];

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);
  const sliderRef = useRef(null);

  // Update isTablet state on window resize
  useEffect(() => {
    const handleResize = () => setIsTablet(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to the current index (for slider)
  useEffect(() => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollWidth = reviewWidth * (extendedReviews.length / 3); // Divide by 3 to avoid jumping
      sliderRef.current.scrollTo({
        left: scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [isTablet]);

  // Navigate to the previous slide
  const scrollLeft = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: -reviewWidth,
        behavior: 'smooth',
      });
    }
  };

  // Navigate to the next slide
  const scrollRight = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      sliderRef.current.scrollBy({
        left: reviewWidth,
        behavior: 'smooth',
      });
    }
  };

  // Handle scroll events to create an infinite loop
  const handleScroll = () => {
    if (sliderRef.current) {
      const reviewWidth = sliderRef.current.querySelector('.review').offsetWidth;
      const scrollLeft = sliderRef.current.scrollLeft;
      const scrollWidth = reviewWidth * extendedReviews.length;

      if (scrollLeft >= scrollWidth - sliderRef.current.clientWidth) {
        sliderRef.current.scrollTo({
          left: reviewWidth,
          behavior: 'smooth',
        });
      } else if (scrollLeft <= 0) {
        sliderRef.current.scrollTo({
          left: scrollWidth - reviewWidth * 2,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if(sliderRef.current){
    const container = sliderRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [extendedReviews.length]);

  return (
    <div className="reviews-wrapper">
      <div className="reviews-container">
        <h2 className="reviews-header">Відгуки наших студентів:</h2>
        {isTablet ? (
          <>
            <div className="reviews-slider" ref={sliderRef}>
              {extendedReviews.map((review, index) => (
                <div className="review" key={index}>
                  <img
                    className="review-image"
                    src={review.image}
                    alt={review.altText}
                    draggable={false}
                  />
                </div>
              ))}
            </div>
            <div className="slider-controls">
              <button className="slider-button left" onClick={scrollLeft}>
                <FontAwesomeIcon icon={faArrowLeft} color='dodgerblue' size='2x'/>
              </button>
              <button className="slider-button right" onClick={scrollRight}>
                <FontAwesomeIcon icon={faArrowRight} color='dodgerblue' size='2x'/>
              </button>
            </div>
          </>
        ) : (
          <Draggable rootClass="reviews-slider">
            {extendedReviews.map((review, index) => (
              <div className="review" key={index}>
                <img
                  className="review-image"
                  src={review.image}
                  alt={review.altText}
                  draggable={false}
                />
              </div>
            ))}
          </Draggable>
        )}
      </div>
    </div>
  );
};

export default Reviews;

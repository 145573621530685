import React from "react"

const Course1 = (props) => {
  return (
    <div>
      <h1>1 Course</h1>
    </div>
  )
};

export default Course1;

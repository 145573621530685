import React from "react"

const PrivacyPolicy = (props) => {
  return (
    <div>
      
    </div>
  )
};

export default PrivacyPolicy;

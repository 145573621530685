import React from "react"

const RefundPolicy = (props) => {
  return (
    <div>
      
    </div>
  )
};

export default RefundPolicy;

import Navbar from "./elements/Navbar";
import "./styles/style.css"
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./elements/Footer"
import Home from "./elements/Home"
import Courses from "./elements/Courses"
import AboutMe from "./elements/AboutMe";
import Course1 from "./elements/courses/Course1.jsx"
import RefundPolicy from './elements/RefundPolicy.jsx'
import PrivacyPolicy from './elements/PrivacyPolicy.jsx'
import ContractForm from './elements/ContractForm.jsx'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/courses" element={<Courses />} /> 
            <Route exact path="/aboutme" element={<AboutMe />} />
            <Route exact path="/refund-policy" element={<RefundPolicy />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/contract-form" element={<ContractForm />} />
            <Route exact path="/course-1" element={<Course1 />} />                            
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React, { useRef, useState, useEffect } from 'react';
import '../styles/ReviewSlider.css';

const Draggable = ({ rootClass = "", children }) => {
  const ourRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  // Clone children for infinite scrolling and assign unique keys
  const reviews = React.Children.toArray(children);
  const clonedChildren = [
    ...reviews.map((child, index) => React.cloneElement(child, { key: `original-${index}` })),
    ...reviews.map((child, index) => React.cloneElement(child, { key: `duplicate-1-${index}` })),
    ...reviews.map((child, index) => React.cloneElement(child, { key: `duplicate-2-${index}` }))
  ];

  // Mouse events
  const handleMouseDown = (e) => {
    if (!ourRef.current) return;
    setIsDragging(true);
    startX.current = e.pageX - ourRef.current.offsetLeft;
    scrollLeft.current = ourRef.current.scrollLeft;
    document.body.style.cursor = 'grabbing';
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !ourRef.current) return;
    e.preventDefault();
    const x = e.pageX - ourRef.current.offsetLeft;
    const walk = (x - startX.current) * 1; // Adjust scroll speed here
    ourRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  // Touch events
  const handleTouchStart = (e) => {
    if (!ourRef.current) return;
    setIsDragging(true);
    startX.current = e.touches[0].pageX - ourRef.current.offsetLeft;
    scrollLeft.current = ourRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !ourRef.current) return;
    const x = e.touches[0].pageX - ourRef.current.offsetLeft;
    const walk = (x - startX.current) * 1; // Adjust scroll speed here
    ourRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // Setup event listeners
  useEffect(() => {
    const element = ourRef.current;

    if (!element) return;

    element.addEventListener('mousemove', handleMouseMove);
    element.addEventListener('mouseup', handleMouseUp);
    element.addEventListener('mouseleave', handleMouseUp);
    element.addEventListener('mousedown', handleMouseDown);

    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handleTouchEnd);
    element.addEventListener('touchstart', handleTouchStart);

    return () => {
      element.removeEventListener('mousemove', handleMouseMove);
      element.removeEventListener('mouseup', handleMouseUp);
      element.removeEventListener('mouseleave', handleMouseUp);
      element.removeEventListener('mousedown', handleMouseDown);

      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
      element.removeEventListener('touchstart', handleTouchStart);
    };
  }, [isDragging]);

  // Adjust the scroll position on mount
  useEffect(() => {
    const element = ourRef.current;
    if (element) {
      // Initially scroll to the middle of the duplicated content
      element.scrollLeft = element.scrollWidth / 3; // Adjust based on number of clones
    }
  }, []);

  // Handle scroll event to loop infinitely
  const handleScroll = () => {
    if (!ourRef.current) return;
    const element = ourRef.current;
    const itemWidth = element.scrollWidth / 3; // Adjust based on number of duplicates

    // Scroll adjustment for seamless infinite scrolling
    if (element.scrollLeft >= itemWidth * 2) {
      element.scrollLeft -= itemWidth;
    } else if (element.scrollLeft < 0) {
      element.scrollLeft += itemWidth;
    }
  };

  useEffect(() => {
    const element = ourRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div
      ref={ourRef}
      className={`${rootClass} flex overflow-x-auto cursor-grab snap-x snap-mandatory`}
    >
      {clonedChildren}
    </div>
  );
};

export default Draggable;

import React, { useState, useEffect } from 'react';
import '../styles/liveCourse.css';

const LiveCourse = ({ courseDate, courseName }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(courseDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const isTimeUp = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;

  return (
    <div className="live-course-container">
      <div>
        <h2>Актуальні курси</h2>
        <div className="course-info">
          <h3>Живий формат</h3>
          <p>Наступний курс: <strong>{courseName}</strong></p>
          <p>Дата курсу: <strong>{new Date(courseDate).toLocaleDateString()}</strong></p>
        </div>
        <div className="timer">
          <span>{timeLeft.days} дні(в)</span> : 
          <span>{timeLeft.hours} годин(и)</span> : 
          <span>{timeLeft.minutes} хвилин(и)</span> : 
          <span>{timeLeft.seconds} секунд(и)</span>
        </div>
        {isTimeUp && <div className="time-up-message">Курс вже розпочався! Поспішайте приєднатися!</div>}
      </div>
    </div>
  );
};

export default LiveCourse;

import React from "react"
import '../styles/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faHeartPulse } from "@fortawesome/free-solid-svg-icons/faHeartPulse";


const CareService = (props) => {
  return (
    <a href="https://t.me/sevastianovaas" className={`navbar-care-service ${props.class}`}>
        <FontAwesomeIcon icon={faTelegram} size="3x" color="Dodgerblue"/>
        <h2>СЛУЖБА ТУРБОТИ</h2>
        <FontAwesomeIcon icon={faHeartPulse} size="3x" color="Dodgerblue"/>
    </a>
  )
};

export default CareService;

import React from "react"

const ContractForm = (props) => {
  return (
    <div>
      
    </div>
  )
};

export default ContractForm;

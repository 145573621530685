import React from "react"
import NastyaMain from "../img/nastya-main-3.png"
import WeInSocialNetwork from "./WeInSocialNetwork";
import ReviewSlider from "./ReviewSlider";
import { Link } from "react-router-dom";
import LiveCourse from "./LiveCourse";
import CareService from "./CareService";

const Home = (props) => {
  return (
    <div>
        <div className="main-container">
          <div className="name-container">
            <h1>Анастасія Севастьянова</h1>
            <p>Вчитель, з яким цікаво <blue>навчатись</blue></p>
            <Link to={"/courses"}><button>Курси</button></Link>
            <Link to={"/aboutme"}><button>Про мене</button></Link>
          </div>
          <img src={NastyaMain} alt="woman-decoy" />
        </div>
        <WeInSocialNetwork instagram={true} />
        <CareService class={'care-service-relative'}/>
        <ReviewSlider />
        <LiveCourse courseDate="2024-08-31T00:00:00" courseName="Техніки з вивчення іноземних слів на основі 3 форм дієслів" />
    </div>
  )
};

export default Home;

import React from "react"
import '../styles/AboutMe.css'
import NastyaImage from '../img/nastya-main-4.jpg'

const AboutMe = (props) => {
  return (
    <div>
        <div className="about-me-container">
          <div className="about-me-content">
            <img src={NastyaImage} alt="nastya-image" className="about-me-image"/>
            <p className="about-me-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo asperiores laboriosam a autem iure cum maxime magni qui, quam commodi veniam sequi deleniti voluptatem, quasi doloribus! Sed illo non doloremque!
            Nobis, magnam! Suscipit eos tenetur ad natus. Voluptatibus repudiandae a pariatur nesciunt, assumenda placeat natus expedita cum enim dolor, aperiam id eos adipisci? Iste dolore pariatur animi mollitia, labore quaerat.
            Minus dolorem fugit sed consequuntur officia, maiores expedita assumenda eveniet. Tempora dicta odit laborum quod, dignissimos libero nobis praesentium corporis quisquam, vero sit laboriosam exercitationem et neque provident repudiandae voluptate.</p>
            <p className="about-me-paragraph">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam cupiditate debitis sapiente voluptate, vel aspernatur, similique atque minima dignissimos placeat veniam ad iusto. Provident fuga alias maiores atque. Necessitatibus, quis!
            Distinctio dolore soluta iusto. Nisi rem unde delectus illo tenetur excepturi eaque minus non, mollitia aperiam eius facilis, necessitatibus minima natus? A odio, animi rem sit ea eius fuga mollitia!
            Iste ipsum porro repudiandae inventore? Quae doloribus consectetur soluta, incidunt itaque nemo ea quisquam cum nobis nam aliquam ipsa, nostrum molestiae, tempore non laborum rem quaerat! Nisi, tempora. Doloremque, ut.</p>
            <img src={NastyaImage} alt="nastya-image" className="about-me-image"/>
            <img src={NastyaImage} alt="nastya-image" className="about-me-image"/>
            <p className="about-me-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ex architecto veritatis possimus cum labore eos id dolore earum eligendi nam amet harum, rem soluta voluptate pariatur facilis, porro sunt.
            Qui totam obcaecati vitae vel culpa eaque aliquam incidunt nemo eius, quia deserunt voluptate laboriosam ipsa quae molestiae distinctio eveniet soluta excepturi quasi non quis? Maxime quo pariatur quos sunt.
            Ullam illum aspernatur accusantium assumenda quidem placeat natus aut dolores corporis excepturi sit itaque pariatur perferendis similique praesentium laboriosam, incidunt ex fuga doloribus reprehenderit. Consequuntur quibusdam nihil tempore distinctio animi!</p>
          </div>
        </div>      
    </div>
  )
};

export default AboutMe;
